/* You can add global styles to this file, and also import curriculum-vitae style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
}

.grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.w-100 {
  width: 100%;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.info-background {
  background: #8cd5f7;
  padding: 5px;
}

.pointer {
  cursor: pointer;
}

.gap-4 {
  gap: 16px;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.6;
}

.p-inputnumber {
  width: 100%;
}

ol.k {
  list-style-type: lower-alpha;
}

.cursor-none {
  cursor: not-allowed;
}

.p-column-filter-buttonbar .p-button {
  margin-right: 10px !important;
}

.custom-datepicker .p-datepicker {
  top: 120% !important;
}

.program-block {
  position: relative;
  aspect-ratio: 3 / 2;
  width: 85%;
  background: linear-gradient(to bottom, #dbf6fe, #ffffff);
  // border-bottom: 4px solid #a6d0e1;
}

.program-block::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 85%;
  height: 4px;
  background: linear-gradient(to right, #4c97af 30%, #6aa5c2 80%, #ffffff 100%);
}

.program-details {
  font-size: small;
}

.p-splitbutton {
  display: inline-flex;
  position: relative;
}

.p-splitbutton {
  border-radius: 6px;

  .p-button {
    border-radius: 0px;
  }
}